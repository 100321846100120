function OptusWhiteLogo() {
  return (
    <svg
      height="40px"
      style={{
        padding: '8px 0',
        color: '#fff',
        marginRight: 15,
        overflow: 'hidden',
        transition: 'all 120ms ease-in-out',
      }}
      viewBox="0 0 480 100"
    >
      <path
        fill="currentColor"
        d="M396.673,86.617c12.383,7.182,26.461,10.923,40.777,10.835c23.895,0,40.825-11.802,40.825-31.441c0-14.947-10.835-22.734-31.054-26.265l-4.16-0.726c-15.479-2.66-19.59-4.644-19.59-8.9c0-4.982,5.708-7.739,15.575-7.739c7.933,0,15.575,1.645,23.605,5.321c1.935,0.871,3.192,0.967,4.644-0.484c2.419-2.419,5.95-8.658,7.207-13.06c0.58-2.177-0.145-3.483-1.838-4.353C462.941,4.967,450.896,2.5,438.707,2.5c-24.621,0-39.712,10.932-40.051,29.071c-0.387,16.204,10.69,24.379,30.425,27.426l4.74,0.726c14.995,2.322,19.251,4.111,19.251,8.9c0,5.466-5.853,8.852-16.204,8.852c-9.481,0-19.106-3.144-30.183-8.997c-2.08-1.113-3.047-0.967-4.644,0.629c-2.419,2.419-5.804,8.707-7.062,13.157C394.351,84.44,395.125,85.649,396.673,86.617"
      />
      <path
        fill="currentColor"
        d="M358.798,58.03c0,12.528-7.739,18.478-18.961,18.478s-18.526-5.95-18.526-18.478V7.966c0-2.177-0.339-3.289-2.66-4.015c-1.838-0.629-4.837-1.113-8.9-1.113c-4.015,0-7.062,0.484-8.9,1.113c-2.273,0.726-2.66,1.838-2.66,4.015v50.838c0,25.056,15.72,38.697,41.599,38.697c26.12,0,41.937-13.641,41.937-38.697V8.014c0-2.177-0.387-3.289-2.709-4.015c-1.79-0.629-4.74-1.113-8.755-1.113c-4.015,0-6.917,0.484-8.755,1.113c-2.322,0.726-2.709,1.838-2.709,4.015L358.798,58.03z"
      />
      <path
        fill="currentColor"
        d="M244.498,96.968c4.015,0,7.062-0.484,8.9-1.113c2.322-0.726,2.66-1.79,2.66-4.015v-67.09h24.089c2.322,0,3.192-0.822,3.773-2.66c0.484-1.451,0.967-4.16,0.967-7.546s-0.484-6.095-0.967-7.546c-0.58-1.838-1.451-2.66-3.773-2.66h-71.347c-2.273,0-3.144,0.822-3.773,2.66c-0.484,1.451-0.967,4.16-0.967,7.546s0.484,6.095,0.967,7.546c0.629,1.838,1.451,2.66,3.773,2.66h24.137v67.09c0,2.225,0.339,3.289,2.66,4.015C237.436,96.484,240.435,96.968,244.498,96.968"
      />
      <path
        fill="currentColor"
        d="M159.511,4.338h-38.116c-1.838,0-3.047,1.064-3.047,3.047v84.455c0,2.225,0.387,3.289,2.709,4.015c1.79,0.629,4.837,1.113,8.852,1.113c4.015,0,7.062-0.484,8.9-1.113c2.322-0.726,2.66-1.79,2.66-4.015V68.478h18.284c23.702,0,36.423-12.915,36.423-31.925C196.176,17.108,183.503,4.338,159.511,4.338z M159.124,49.129h-17.655V24.025h17.655c8.32,0,13.544,4.257,13.544,12.576C172.668,44.631,167.444,49.129,159.124,49.129z"
      />
      <path
        fill="currentColor"
        d="M52.128,2.5C22.525,2.5,1.726,22.574,1.726,50c0,27.426,20.799,47.452,50.402,47.452S102.53,77.378,102.53,50C102.53,22.574,81.731,2.5,52.128,2.5z M52.128,76.507c-15.333,0-26.314-10.835-26.314-26.507c0-15.672,10.98-26.604,26.314-26.604c15.334,0,26.314,10.883,26.314,26.604C78.442,65.72,67.462,76.507,52.128,76.507z"
      />
    </svg>
  );
}

export default OptusWhiteLogo;
