import { createTheme, ThemeOptions } from '@mui/material';
import { green, lightBlue, red } from '@mui/material/colors';

import RingCentral from '../../components/RingCentral';
import Optus from '../../components/Optus';
import RingCentralWhiteLogo from '../../components/RingCentralWhiteLogo';
import OptusWhiteLogo from '../../components/OptusWhiteLogo';

// Optus Theme

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: '#00828e',
    },
    secondary: {
      main: '#fdcc08',
      dark: '#e9ba02',
    },
    success: {
      main: green[600],
      dark: green[500],
    },
    error: {
      main: red[500],
      dark: red[500],
    },
    info: {
      main: lightBlue[600],
      dark: lightBlue[500],
    },
  },
  typography: {
    fontFamily: 'Poppins',
    fontSize: 13,
    htmlFontSize: 17,
    button: {
      fontSize: '.9em',
      lineHeight: 2,
      letterSpacing: '0.08em',
    },
  },
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       '&:hover': {
    //         backgroundColor: '#1A7BB5',
    //         color: '#fff',
    //       },
    //     },
    //   },
    // },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '.9em',
        },
      },
    },
    // MuiListItem: {
    //   styleOverrides: {
    //     root: {
    //       '&&.Mui-selected': {
    //         backgroundColor: '#FAE5D1',
    //         color: '#852800',
    //         '&:hover': {
    //           backgroundColor: '#FAE5D1',
    //           color: '#852800',
    //         },
    //       },
    //     },
    //   },
    // },
  },
};
export const appTheme = createTheme(theme);

// Ring Central Theme

// const theme: ThemeOptions = {
//   palette: {
//     primary: {
//       main: '#066FAC',
//       dark: '#024688',
//       light: '#FAE5D1',
//       contrastText: '#fff',
//     },
//     secondary: {
//       main: '#fff',
//       dark: '#852800',
//       light: '#FAE5D1',
//       contrastText: '#852800',
//     },
//     success: {
//       main: green[600],
//       dark: green[500],
//     },
//     error: {
//       main: red[500],
//       dark: red[500],
//     },
//     info: {
//       main: lightBlue[600],
//       dark: lightBlue[500],
//     },
//   },
//   typography: {
//     fontFamily: 'Poppins',
//     fontSize: 13,
//     htmlFontSize: 17,
//     button: {
//       fontSize: '.9em',
//       lineHeight: 2,
//       letterSpacing: '0.08em',
//     },
//   },
//   components: {
//     MuiMenuItem: {
//       styleOverrides: {
//         root: {
//           fontSize: '.9em',
//         },
//       },
//     },
//   },
// };

// export const appTheme = createTheme(theme);

// Optus app theme settings
export const appThemeSettings = {
  loginLogo: Optus,
  loginTitle: 'BroadCloud to RingCentral Migration',
  favicon: '../../../public/favicon.ico',
  pageTitle: 'BroadCloud 2 RingCentral - Optus',
  customerColumnLabel: 'Optus Customer Number',
  businessCenterLabel: 'Optus Business Center',
  name: 'Optus',
  backgGroundPathFill1: '#3ca6ad',
  backgGroundPathFill2: '#3ca6ad',
  headerLogo: OptusWhiteLogo,
};

// Ring Central app theme settings
// export const appThemeSettings = {
//   loginLogo: RingCentral,
//   loginTitle: 'Service Provider Migration Service',
//   favicon:
//     'https://netstorage.ringcentral.com/appext/logo/TFQ9Uh2rTbiYGu9M_R7pAw~yV0_eNqeT6-sWPXFJev-6A/c448bc9c-339e-4d73-b962-59b2c3a96350.png',
//   pageTitle: 'Service Provider Migration Service',
//   customerColumnLabel: 'Customer Number',
//   businessCenterLabel: 'Business Center',
//   name: 'RingCentral',
//   backgGroundPathFill1: '#0670AF',
//   backgGroundPathFill2: '#378BBD',
//   headerLogo: RingCentralWhiteLogo,
// };
