// RingCentral logo
import OptusLogo from '../images/optus/Optus_logo.png';

const Optus = () => {
  return (
    <div>
      <img
        src={OptusLogo}
        alt={' Logo'}
        style={{ width: '32%', marginBottom: '10px' }}
      />
    </div>
  );
};

export default Optus;
